import React, { useContext, useEffect, useState } from "react"
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  CardBody,
  Card,
} from "reactstrap"
import FileUpload from "../FileUpload"
import NotificationEmails from "../NotificationEmails"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import Services from "../Services"
import { MerchantCreateContext } from "../index"
import { logoutUser, returnStateLogsTransactionUpdate } from "store/actions"

import { OfficeIds } from "../OfficeIds"
import FourthUser from "./FourthUser"
//const axios = require('axios');

const images = [
  {
    type: "",
    formData: {},
  },
]

export const SecondConfigurations = props => {
  const {
    handleMerchantChange,
    merchantDetails,
    handleSubmit,
    toggleTabProgress,
    activeTabProgress,
    userAdmin,
    handleUserAdminChange,
    handleMobilePlusAdminChange,
    submitClicked,
    countryCodes,
  } = props

  const { settingSecondStepError } = useContext(MerchantCreateContext)
  const [formDataFile, setFormDataFile] = useState({})
  const [fileLogo, setFileLogo] = useState(null)
  const [fileBanner, setFileBanner] = useState(null)
  const [files, setFiles] = useState([])
  const [fileUrls, setFileUrls] = useState([])
  const [validEmailList, setValidEmailList] = useState([])
  const [validOfficeIdList, setValidOfficeIdList] = useState([])
  const [validServiceList, setValidServiceList] = useState([])
  const [isLogoValid, setLogoValid] = useState()
  const [isBannerValid, setBannerValid] = useState()
  const [MobilePlusAdmin, setMobilePlusAdmin] = useState("+974")
  useEffect(() => {}, [formDataFile])
  useEffect(() => {}, [fileLogo])
  useEffect(() => {}, [fileBanner])
  useEffect(() => {}, [userAdmin])
  useEffect(() => {
    document.getElementById("formFile").value === "" && setLogoValid(false)
  }, [isLogoValid])

  useEffect(() => {
    document.getElementById("formFile2").value === "" && setBannerValid(true)
  }, [isBannerValid])

  useEffect(() => {
    setValidEmailList(validEmailList)
  }, [validEmailList])

  useEffect(() => {
    setValidOfficeIdList(validOfficeIdList)
  }, [validOfficeIdList])

  useEffect(() => {
    setValidServiceList(validServiceList)
  }, [validServiceList])

  function handleChangeUser(changes) {
    handleUserAdminChange({ ...userAdmin, ...changes })
  }

  function getExtension(filename) {
    return filename.split(".").pop()
  }
  function handleMobileAdminPChange(changes) {
    handleMobilePlusAdminChange(changes)
  }
  const handleLogo = e => {
    e.preventDefault()

    var fileExtension = getExtension(e.target.value).toLowerCase()

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png"
    ) {
      handleChange({ Logo: e.target.value })
      setFileLogo(e.target.files[0])

      const formDataLogo = new FormData()
      formDataLogo.append("file", e.target.files[0])
      formDataLogo.append("upload_preset", "payalllink")
      formDataLogo.append("cloud_name", "DeveloperQa")

      handleFileUpload("logo", formDataLogo)
    } else {
      setLogoValid(false)
    }
  }

  const handleBanner = e => {
    e.preventDefault()

    var fileExtension = getExtension(e.target.value).toLowerCase()

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png"
    ) {
      setBannerValid(true)
      handleChange({ Banner: e.target.value })
      setFileBanner(e.target.files[0])

      const formDataBanner = new FormData()
      formDataBanner.append("file", e.target.files[0])
      formDataBanner.append("upload_preset", "payalllink")
      formDataBanner.append("cloud_name", "DeveloperQa")

      handleFileUpload("banner", formDataBanner)
    } else {
      setBannerValid(false)
    }
  }

  function handleOfficeIdChange(id, officeId) {
    const newOfficeIds = [...merchantDetails.OfficeIds]
    const index = newOfficeIds.findIndex(i => i.id === id)
    newOfficeIds[index] = officeId
    handleChange({ OfficeIds: newOfficeIds })
  }

  function handleOfficeIdAdd() {
    const newOfficeId = {
      id: uuidv4(),
      OfficeId: "",
      Date: new Date().toISOString(),
    }
    handleChange({ OfficeIds: [...merchantDetails.OfficeIds, newOfficeId] })
  }

  function handleOfficeIdDelete(id, index) {
    handleChange({
      OfficeIds: merchantDetails.OfficeIds.filter(i => i.id !== id),
    })

    let tempArray = validOfficeIdList.filter(
      item => !item.includes(index.toString())
    )
    setValidOfficeIdList(tempArray)
  }

  // const handleFileUpload = async () => {

  //     //  if (!formDataFile.entries().next().done) {
  //     // try {

  //     // //https://payalllink.netlify.app/
  //     // //http://localhost:5000
  //     // await fetch("http://localhost:5000/upload", {
  //     //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //     //     mode: 'cors', // no-cors, *cors, same-origin
  //     //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //     //     credentials: 'same-origin', // include, *same-origin, omit
  //     //     // redirect: 'follow', // manual, *follow, error
  //     //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     //     body: formDataFile // body data type must match "Content-Type" header
  //     // })
  //     //     .then(response => checkJson(response))
  //     //     .then(result => {
  //     //         if (result.code === "LIMIT_FILE_SIZE") { console.error('Error:', "Follow Image Requirement!") }
  //     //         else {
  //     //             console.log('Success:', result)

  //     //             const logoData = result.filter(x => x.fieldname == "logo")
  //     //             const bannerData = result.filter(x => x.fieldname == "banner")

  //     //             handleChange({ Logo: logoData[0].filename, Banner: bannerData[0].filename })
  //     //         }
  //     //     })
  //     //     .catch(error => {
  //     //         console.error('Error:', error);
  //     //     });

  //     console.log("file sent")
  //     console.log(files)

  //     files.map(async (formDataFile) => {
  //         await fetch("https://api.cloudinary.com/v1_1/developerqa/image/upload", {
  //             method: "post",
  //             body: formDataFile?.formData
  //         })
  //             .then(resp => resp.json())
  //             .then(data => {

  //                 console.log(formDataFile?.type)

  //                 if (formDataFile?.type === "logo") {
  //                     console.log("hanlding logo")
  //                     handleChange({ Logo: data.url })
  //                 }

  //                 if (formDataFile?.type === "banner") {
  //                     console.log("hanlding banner")
  //                     handleChange({ Banner: data.url })
  //                 }

  //                setFileUrls([...fileUrls, data.url])
  //                 console.log(data.url)

  //             })
  //             .catch(err => console.log(err))
  //     })

  //     //setFiles([])

  // }

  const handleFileUpload = async (type, formData) => {
    await fetch("https://api.cloudinary.com/v1_1/developerqa/image/upload", {
      method: "post",
      body: formData,
    })
      .then(resp => resp.json())
      .then(data => {
        if (type === "logo") {
          handleChange({ Logo: data.url })
          setLogoValid(true)
        }

        if (type === "banner") {
          handleChange({ Banner: data.url })
        }
      })
      .catch(err => console.log(err))
  }

  const addFile = data => {
    setFiles([...files, data])
  }

  const addUrl = data => {
    setFileUrls([...fileUrls, data])
  }

  function checkJson(response) {
    return response.text().then(function (text) {
      return text ? JSON.parse(text) : {}
    })
  }

  function handleChange(changes) {
    handleMerchantChange({ ...merchantDetails, ...changes })
  }

  function handleNotificationChange(id, notificationemail) {
    const newNotifications = [...merchantDetails.NotificationEmails]
    const index = newNotifications.findIndex(i => i.id === id)
    newNotifications[index] = notificationemail
    handleChange({ NotificationEmails: newNotifications })
  }

  function handleNotificationAdd() {
    const newNotification = {
      id: uuidv4(),
      Email: "",
      Date: new Date().toISOString(),
    }
    handleChange({
      NotificationEmails: [
        ...merchantDetails.NotificationEmails,
        newNotification,
      ],
    })
  }

  function handleNotificationDelete(id, index) {
    handleChange({
      NotificationEmails: merchantDetails.NotificationEmails.filter(
        i => i.id !== id
      ),
    })

    let tempArray = validEmailList.filter(
      item => !item.includes(index.toString())
    )
    setValidEmailList(tempArray)
  }

  function handleServiceChange(id, service) {
    const newServices = [...merchantDetails.Services]
    const index = newServices.findIndex(i => i.id === id)
    newServices[index] = service
    handleChange({ Services: newServices })
  }

  function handleServiceAdd() {
    const newService = {
      id: uuidv4(),
      Service: "",
    }

    handleChange({ Services: [...merchantDetails.Services, newService] })
  }

  function handleServiceDelete(id) {
    handleChange({
      Services: merchantDetails.Services.filter(i => i.id !== id),
    })
  }

  const renderSubmitButton = () => {
    if (
      activeTabProgress === 2 &&
      isFirstNameValid &&
      isLastNameValid &&
      isUserEmailValid &&
      isUserEmailValidMessage &&
      isUserMobileValid &&
      isDepartmentValid &&
      isLogoValid &&
      isBannerValid &&
      merchantDetails.Logo !== "" &&
      merchantDetails.Logo !== undefined &&
      merchantDetails.NotificationEmails[0].Email !== "" &&
      merchantDetails.NotificationEmails.length === validEmailList.length &&
      submitClicked !== true
    ) {
      if (
        merchantDetails.Type === "Travel Agency" &&
        merchantDetails.OfficeIds[0].OfficeId !== "" &&
        merchantDetails.OfficeIds.length === validOfficeIdList.length
      ) {
        if (merchantDetails.Services[0].Service !== "") {
          return (
            <Button color="success" onClick={() => handleSubmit()}>
              <i className="fas fa-paper-plane" /> Submit Now
            </Button>
          )
        }

        //pop out default
        merchantDetails.Services.pop()

        const service_1 = {
          id: uuidv4(),
          Service: "Flight",
        }
        const service_2 = {
          id: uuidv4(),
          Service: "Hotels",
        }
        const service_3 = {
          id: uuidv4(),
          Service: "Visa Processing",
        }
        merchantDetails.Services.push(service_1)
        merchantDetails.Services.push(service_2)
        merchantDetails.Services.push(service_3)

        return (
          <Button color="success" onClick={() => handleSubmit()}>
            <i className="fas fa-paper-plane" /> Submit Now
          </Button>
        )
      }

      if (
        merchantDetails.Type !== "Travel Agency" &&
        merchantDetails.Services[0].Service !== "" &&
        merchantDetails.Services.length === validServiceList.length
      ) {
        return (
          <Button color="success" onClick={() => handleSubmit()}>
            <i className="fas fa-paper-plane" /> Submit Now
          </Button>
        )
      }
    }

    return (
      <Button disabled color="primary" id="my-form_submit_5">
        {" "}
        <i className="fas fa-paper-plane" /> Submit Now{" "}
      </Button>
    )
  }

  const [isFirstNameValid, setIsFirstNameValid] = useState()

  function handleFirstName(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
      setIsFirstNameValid(true)
    } else {
      setIsFirstNameValid(false)
    }

    handleChangeUser({ FirstName: value })
  }

  const [isLastNameValid, setIsLastNameValid] = useState()

  function handleLastName(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
      setIsLastNameValid(true)
    } else {
      setIsLastNameValid(false)
    }

    handleChangeUser({ LastName: value })
  }

  const [isUserEmailValid, setIsUserEmailValid] = useState()
  const [isUserEmailValidMessage, setIsUserEmailValidMessage] =
    useState(undefined)
  useEffect(() => {
    setIsUserEmailValid(isUserEmailValid)
  }, [isUserEmailValid])
  useEffect(() => {
    setIsUserEmailValidMessage(isUserEmailValidMessage)
  }, [isUserEmailValidMessage])

  function handleUserEmail(value) {
    var pattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (pattern.test(value)) {
      setIsUserEmailValid(true)
    } else {
      setIsUserEmailValid(false)
    }
    setIsUserEmailValidMessage(undefined)
    handleChangeUser({ Email: value })
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      if (isUserEmailValid) {
        isUserExisting(event.target.value)
      } else {
        setIsUserEmailValidMessage(undefined)
      }
    }
  }

  function handleUserEmailOnBlur(value) {
    if (isUserEmailValid) {
      isUserExisting(value)
    } else {
      setIsUserEmailValidMessage(undefined)
    }
  }

  const [isUserMobileValid, setIsUserMobileValid] = useState()

  function handleMobile(value) {
    var pattern = /^(\+|)[0-9]{8,}$/

    if (pattern.test(value)) {
      setIsUserMobileValid(true)
    } else {
      setIsUserMobileValid(false)
    }

    handleChangeUser({ Mobile: value })
  }

  const [isDepartmentValid, setIsDepartmentValid] = useState()

  function handleDepartment(value) {
    var pattern = /(.|\s)*\S(.|\s)*/

    if (pattern.test(value)) {
      setIsDepartmentValid(true)
    } else {
      setIsDepartmentValid(false)
    }

    handleChangeUser({ Department: value })
  }

  function isUserExisting(email) {
    fetch("https://paluat.acoqa.dev/api/users/checkexistinguser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    })
      .then(res => res.json())
      .then(res => {
        //existing on db
        if (res.data) {
          setIsUserEmailValid(false)
          setIsUserEmailValidMessage(false)
        }

        //not existing on db so valid to use
        if (res.data === false && isUserEmailValid) {
          setIsUserEmailValid(true)
          setIsUserEmailValidMessage(true)
        } else {
          setIsUserEmailValid(false)
          setIsUserEmailValidMessage(false)
        }
      })
      .catch(err => {
        setIsUserEmailValid(false)
        setIsUserEmailValidMessage(false)
      })
  }

  return (
    <div>
      <Form>
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="basicpill-merchantname-input14">
                    Logo <span style={{ color: "red" }}>*</span>
                    <small>
                      ( Requirements: 200px by 200px - less than 50kb - jpeg &
                      png only)
                    </small>
                  </Label>
                  {/* <FileUpload/> */}

                  <input
                    //value={merchantDetails.Logo}
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept="image/*"
                    name="Logo"
                    onChange={e => handleLogo(e)}
                  />

                  {isLogoValid === false && (
                    <small className="color-danger" style={{ float: "left" }}>
                      Invalid Logo.
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="basicpill-merchantname-input14">
                    Marketing Banner{" "}
                    <small>
                      ( Requirements: 865px by 270px - less than 150kb - jpeg &
                      png only)
                    </small>
                  </Label>
                  {/* <FileUpload/> */}

                  <input
                    //value={merchantDetails.Banner}
                    className="form-control"
                    type="file"
                    id="formFile2"
                    accept="image/*"
                    name="Banner"
                    onChange={e => handleBanner(e)}
                  />

                  {isBannerValid === false && (
                    <small className="color-danger" style={{ float: "left" }}>
                      Invalid Banner.
                    </small>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <b>
                  Notification Emails <span style={{ color: "red" }}>*</span>
                </b>
                <p>
                  Listed emails will receive the payment request notifications.
                  <label className="color-danger ml-1">
                    Maximum of 3 emails only.
                  </label>
                </p>

                {merchantDetails.NotificationEmails.slice(0, 3).map(
                  (notificationEmail, index) => (
                    <NotificationEmails
                      index={index}
                      key={notificationEmail.id}
                      notificationEmail={notificationEmail}
                      handleNotificationChange={handleNotificationChange}
                      handleNotificationDelete={handleNotificationDelete}
                      validEmailList={validEmailList}
                      setValidEmailList={setValidEmailList}
                    />
                  )
                )}

                <Button
                  onClick={() => handleNotificationAdd()}
                  color="success"
                  className="mt-1"
                >
                  <i className="fa fas fa-plus" /> Notification Email
                </Button>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                {merchantDetails.Type === "Travel Agency" ? (
                  <>
                    <b>
                      Add OfficeId <span style={{ color: "red" }}>*</span>
                    </b>
                    <p>
                      Registered OfficeId(s) can send payment requests directly
                      from{" "}
                      <label className="color-danger ml-1">
                        Selling Platform Connect (Selco){" "}
                      </label>
                    </p>
                    {merchantDetails.OfficeIds.map((officeId, index) => (
                      <OfficeIds
                        index={index}
                        key={officeId.id}
                        officeId={officeId}
                        handleOfficeIdChange={handleOfficeIdChange}
                        handleOfficeIdDelete={handleOfficeIdDelete}
                        validOfficeIdList={validOfficeIdList}
                        setValidOfficeIdList={setValidOfficeIdList}
                      />
                    ))}

                    <Button
                      onClick={() => handleOfficeIdAdd()}
                      color="success"
                      className="mt-1"
                    >
                      <i className="fa fas fa-plus" /> New OfficeId
                    </Button>
                  </>
                ) : (
                  <>
                    <b>Products/Services Offered</b>
                    <span style={{ color: "red" }}>*</span>
                    <p>
                      Please specify the products & services you are offering.
                      <div>( E.g Food, Home Renovations, Luxury Handbags)</div>
                    </p>

                    {merchantDetails.Services.map((service, index) => (
                      <Services
                        index={index}
                        key={service.id}
                        service={service}
                        handleServiceChange={handleServiceChange}
                        handleServiceDelete={handleServiceDelete}
                        validServiceList={validServiceList}
                        setValidServiceList={setValidServiceList}
                      />
                    ))}

                    <Button
                      onClick={() => handleServiceAdd()}
                      color="success"
                      className="mt-1"
                    >
                      <i className="fa fas fa-plus" /> Service
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <hr></hr>
        <b>Add Admin User</b>
        <p>Create your first access</p>

        <Row>
          <Col lg="2">
            <FormGroup>
              <Label>
                Title <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="custom-select"
                value={userAdmin.Title}
                name="Title"
                onChange={e => handleChangeUser({ Title: e.target.value })}
              >
                <option default value="Mr">
                  Mr
                </option>
                <option value="Ms">Ms</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup>
              <Label for="basicpill-firstname-input14">
                First Name <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                value={userAdmin.FirstName}
                name="FirstName"
                onChange={e => handleFirstName(e.target.value)}
                onBlur={e => handleFirstName(e.target.value)}
                type="text"
                className="form-control"
                id="FirstName"
              />
              {isFirstNameValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid First Name.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup>
              <Label for="basicpill-lastname-input14">
                Last Name <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                value={userAdmin.LastName}
                name="LastName"
                onChange={e => handleLastName(e.target.value)}
                onBlur={e => handleLastName(e.target.value)}
                type="text"
                className="form-control"
                id="LastName"
              />
              {isLastNameValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Last Name.
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-email-input14">
                Email <span style={{ color: "red" }}>*</span>{" "}
                <small> (press Enter to check email validity)</small>
              </Label>
              <input
                value={userAdmin.Email}
                name="Email"
                onChange={e => handleUserEmail(e.target.value)}
                onBlur={e => handleUserEmailOnBlur(e.target.value)}
                onKeyDown={e => handleKeyDown(e)}
                type="text"
                className="form-control"
                id="Email"
              />

              {isUserEmailValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Email.{" "}
                </small>
              )}
              {isUserEmailValid && isUserEmailValidMessage && (
                <small style={{ float: "left", color: "green" }}>
                  Valid Email.
                </small>
              )}
              {isUserEmailValidMessage === false && (
                <small className="color-danger ml-1" style={{ float: "left" }}>
                  {" "}
                  Already in use.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-mobile-input14">
                Mobile <span style={{ color: "red" }}>*</span>
              </Label>
              <Row>
                <Col md={4}>
                  <select
                    className="form-control  custom-select input-text-size"
                    id="MobilePlusAdmin"
                    name="MobilePlusAdmin"
                    value={MobilePlusAdmin}
                    onChange={event => {
                      setMobilePlusAdmin(event.target.value)
                      handleMobileAdminPChange(event.target.value)
                    }}
                  >
                    <option value="+974" hidden="hidden" defaultValue>
                      +974
                    </option>
                    {countryCodes?.map((cc, i) => {
                      return (
                        <option value={"+" + cc.countryCode} key={i}>
                          +{cc.countryCode}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col md={8}>
                  {" "}
                  <input
                    value={userAdmin.Mobile}
                    name="Mobile"
                    onChange={e => handleMobile(e.target.value)}
                    onBlur={e => handleMobile(e.target.value)}
                    type="text"
                    className="form-control"
                    id="Mobile"
                  />
                </Col>
              </Row>
              {isUserMobileValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Mobile.
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>
                Department <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="custom-select"
                value={userAdmin.Department}
                name="Department"
                onChange={e => handleDepartment(e.target.value)}
                onBlur={e => handleDepartment(e.target.value)}
              >
                <option value="" default hidden="hidden"></option>
                <option value="Administration">Administration</option>
                <option value="Finance">Finance</option>
                <option value="Operations">Operations</option>
                <option value="Sales and Marketing">Sales & Marketing</option>
              </select>
              {isDepartmentValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Department.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Role</Label>
              <select
                className="custom-select"
                value={userAdmin.Role}
                name="Role"
                onChange={e => handleChangeUser({ Role: e.target.value })}
              >
                <option value="Admin">Admin</option>
              </select>
            </FormGroup>
          </Col>
        </Row>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {renderSubmitButton()}
          </li>
        </ul>
      </Form>
    </div>
  )
}

export default SecondConfigurations
